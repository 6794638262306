@import '../variables';

.user-account {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    height: calc(100% - 0%);
    padding: var(--padding-base-x-4);

    .user-dashboard {
        display: grid;
        grid-template-columns: 2fr 6fr;
        grid-template-rows: 1fr;
        gap: var(--grid-gap);
        width: 100%;
        height: 100%;
        overflow: hidden;
    }
}
