@import '../variables';

.create-wizard {
    display: grid;

    width: 100%;
    height: 100%;
    
    padding: var(--padding-base-x-2);
    z-index: 100;
    overflow: hidden;

    .create-wizard-event {
        display: grid;
        grid-template-rows: auto auto auto 1fr;
        width: 100%;
        height: 100%;
        
        padding: var(--padding-base-x-2);
        z-index: 100;
        overflow: hidden;

        .create-wizard-event-form {
            display: grid;
            grid-template-columns: auto auto 1fr;
            width: 100%;
            height: 100%;
            
            padding: var(--padding-base-x-2);
            z-index: 100;
            overflow: hidden;

            .create-wizard-event-form-group {
                display: grid;
                grid-template-columns: 1fr 3fr;
                gap: var(--grid-gap-x-2);
                width: 100%;
                height: 100%;
                
            }

            .create-wizard-event-form-text {
                display: grid;
                gap: var(--grid-gap-x-2);
                width: 100%;
                height: 100%;
                
                padding: var(--padding-base-x-2);
                z-index: 100;
                overflow: hidden;
            }
    
            .create-wizard-event-form-icon {
                display: grid;
                width: 100%;
                height: 100%;
                
                padding: var(--padding-base-x-2);
                z-index: 100;
                overflow: hidden;

                img {
                    width: 6rem;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }

    .create-wizard-site {
        display: grid;
        grid-template-rows: auto auto auto 1fr;
        width: 100%;
        height: 100%;
        
        padding: var(--padding-base-x-2);
        z-index: 100;
        overflow: hidden;

        .create-wizard-site-form {
            display: grid;
            grid-template-columns: auto auto 1fr;
            width: 100%;
            height: 100%;
            
            padding: var(--padding-base-x-2);
            z-index: 100;
            overflow: hidden;

            .create-wizard-site-form-group {
                display: grid;
                grid-template-columns: 1fr 3fr;
                gap: var(--grid-gap-x-2);
                width: 100%;
                height: 100%;
                
            }

            .create-wizard-site-form-text {
                display: grid;
                gap: var(--grid-gap-x-2);
                width: 100%;
                height: 100%;
                
                padding: var(--padding-base-x-2);
                z-index: 100;
                overflow: hidden;
            }
    
            .create-wizard-event-form-icon {
                display: grid;
                width: 100%;
                height: 100%;
                
                padding: var(--padding-base-x-2);
                z-index: 100;
                overflow: hidden;

                img {
                    width: 6rem;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }
}

.create-wizard-medialibrary-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto auto auto;
    width: 100%;
    height: 100%;
    
    overflow: hidden;   

    .create-wizard-medialibrary {
        width: 100%;
        height: 100%;
        
        overflow: hidden;   

    }
}

.create-wizard-navigation {
    width: 100%;
    height: 100%;
    
    padding: 0 2rem;
    overflow: hidden;   

}