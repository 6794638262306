@import '../variables';

.media-library {
    display: grid;
    grid-template-columns: 1fr 6fr;
    grid-template-rows: 1fr;
    gap: var(--grid-gap);
    height: 100%;
    width: 100%;
    padding: var(--padding-base-x-2);
    overflow: hidden;

    .media-library-left {
        display: grid;
        grid-template-rows: auto 1fr;
        grid-gap: var(--grid-gap-x-4);
        height: 100%;
        width: 100%;
        padding: var(--padding-base-x-2);
        overflow: hidden;

        .selected-media-info {
            display: grid;
            grid-template-rows: auto 1fr;
            grid-gap: var(--grid-gap-x-4);
            height: 100%;
            width: 100%;
             
            .selected-media-details {
                display: grid;
                grid-template-rows: auto auto 1fr;
                width: 100%;
                height: 100%;
                padding: var(--padding-base-x-2);
            }
            
            .selected-media-viewer {
                display: grid;
                width: 100%;
                height: 100%;
                
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }

            .selected-media-options {
                display: flex;
                flex-direction: column;
                align-items: center;
                grid-gap: var(--grid-gap-x-4);
                width: 100%;
                height: 100%;
                
                button {
                    width: 90%;
                    height: 2rem;
                    padding: 0;
                }
            } 
        }

        .drop-area {
            font-size: .6rem;
            display: grid;
            grid-template-rows: 3fr auto;
            grid-gap: 1rem;
            width: 100%;
            height: 100%;
            background-color: rgba($color: #a0a0af, $alpha: .5);
            border: var(--border);
            border-radius: var(--border-radius);
            text-align: center;
            padding: .25rem;

            &.drag-over {
                width: 100%;
                height: 100%;
                background-color: #f0f0f0;
            }
        }

        .upload-status {
            height: 100%;
            width: 100%;
            padding: .25rem;
        }
    }
    
    .media-right {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto 1fr;
        height: calc(100% - 2px);
        width: 100%;
        overflow: hidden;

        .media-filter-buttons {
            display: grid;
            grid-template-columns: repeat(5, 1fr);
            gap: var(--grid-gap);
            width: 100%;
            height: 100%;
            padding: var(--padding-base-x-2);
        }

        .media-files-grid {
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(6rem, 6rem));
            grid-template-rows: repeat(auto-fill, minmax(7.5rem, 7.5rem));
            gap: var(--grid-gap-x-4);
            height: calc(100% - 0px);
            width: 100%;
            padding: var(--grid-gap-x-4);
            overflow-y: auto;

            .media-files-grid-item {
                display: grid;
                grid-template-rows: auto;
                gap: var(--grid-gap-x-4);
                height: 100%;
                width: 100%;
                cursor: pointer;
                border: var(--border);
                transition: border-color 0.3s;
                background-color: var(--color-background);
        
                &.selected {
                    border-color: #007bff;
                }
            }

            .no-media-message {
                height: 100%;
                width: 100%;
            }
        }
    }
    
    .texture-popup {
        position: absolute;
        top: 20%;
        left: 20%;
        background-color: white;
        padding: 20px;
        border: 1px solid black;
        width: 60%;
        max-height: 80%;
        overflow: auto; 

        .image-grid {
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(6rem, 1fr));
            gap: 10px;

            .image-item {
                cursor: pointer;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                border: 1px solid #ccc;
                padding: 5px;

                img {
                    width: 100%;
                    height: auto;
                    object-fit: contain;
                    max-height: 80px;
                }

                p {
                    margin-top: 5px;
                    text-align: center;
                    font-size: 0.8em;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 100px;
                }
            }
        }
    }
}
