@import '../variables';

.viewport {
    display: grid;
    grid-template-rows: auto 1fr;
    gap: .25rem;
    width: 100%;
    height: 100%;

    .viewport-menu {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        gap: .25rem;
        width: 100%;
        height: 100%;
        padding: 0;
    }
    
    .viewport-view {
        width: 100%;
        height: 100%;
        overflow: hidden;
    }
}
