@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');

* {box-sizing: border-box}

html, body, #root {
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-family: 'Inter', sans-serif;
    height: 100%;
    width: 100%;
    overflow: hidden;
  
    // Colors
    --color-base: #EAFEFF;
    --color-selected: #24EBF4;
    --color-disabled: #666666;
    --color-header-footer: rgba(0, 0, 0, 0.75);

    --color-background-transparent: rgba(48, 62, 80, 0.9);;
    --color-background: #303E50;
    --color-background-hover: #25303F;
    --color-background-selected: #161D25;
    --color-modal-background: #161D25;
    --color-modal-backdrop: rgba(22, 29, 37, 0.5);
    --color-button-background-disabled: #cccccc;

    --color-border-selected: var(--color-selected);

    --border-thickness: 1px;
    --border: var(--border-thickness) solid var(--color-base);
    --border-hover: var(--border-thickness) solid var(--color-base);
    --border-selected: var(--border-thickness) solid var(--color-border-selected);
    --border-calendar: var(--border-thickness) solid var(--color-modal-background);
    --border-radius: .25rem;

    --padding-base: .125rem;
    --padding-base-x-2: .25rem;
    --padding-base-x-4: .5rem;
    --padding-base-x-8: 1rem;

    --button-background: var(--color-background);
    --button-background-hover: var(--color-background-hover);
    --button-background-selected: var(--color-background-selected);
    
    --modal-background: var(--color-modal-background);

    --grid-gap: .25rem;
    --grid-gap-x-2: .5rem;
    --grid-gap-x-4: 1rem;

    // Font
    --font-weight-bold: bold;

    // Border
    --border-radius-small: 4px;
    --border-radius-circle: 50%;

    // Others
    --transition-duration: 0.3s;

    // buttons
    button,.fileInputLabel {
        display: inline-block;
        color: var(--color-base);
        background-color:var(--button-background);
        border: var(--border);
        border-radius: var(--border-radius);
        padding: var(--padding-base-x-4);
    }

    button:hover,.fileInputLabel:hover{
        background-color: var(--button-background-hover);
        border: var(--border-hover);
        cursor:pointer;
    }

    button.selected {
        color: var(--color-selected);
        border: var(--border-selected);
        background-color: var(--button-background-selected);
        cursor:pointer;
    }

    button:disabled {
        color: var(--color-disabled);
        background-color: var(--color-button-background-disabled);
        cursor: not-allowed;
    }
    
    a:link {
      display: inline-block;
      color: var(--color-base);
      padding: var(--padding-base-x-4);
      padding: var(--padding-base-x-4);
    }

    a:hover {
      background-color: var(--button-background-hover);
      border: var(--border-hover);
      cursor:pointer;
    }

    a.selected {
      color: var(--color-selected);
      border: var(--border-selected);
      background-color: var(--button-background-selected);
      cursor:pointer;
    }

    a:visited {
        color: var(--color-base);
    }

    // inputs
    // input[type="text"]
    input, input[type="text"], input[type="password"], input[type="email"], input[type="number"], textarea {
        width: 100%;
        height: 100%;
        color: var(--color-base);
        background-color:var(--button-background);
        border: var(--border);
        border-radius: var(--border-radius);
        padding: var(--padding-base-x-4);
        resize: none;
    }



    input:focus, input[type="text"]:focus, input[type="password"]:focus, input[type="email"]:focus, input[type="number"]:focus, textarea:focus {
        outline: none;
        color: var(--color-selected);
        border: var(--border-selected);
        background-color: var(--button-background-selected);
        padding: var(--padding-base-x-4);
    }

    input:hover, input[type="text"]:hover, input[type="password"]:hover, input[type="email"]:hover, input[type="number"]:hover, textarea:hover {
        background-color: var(--button-background-hover);
        border: var(--border-hover);
        cursor:pointer;
    }

    input::placeholder {
      color: var(--color-base);
    }

    textarea {
      white-space: pre-wrap;
      word-wrap: break-word;
      resize: none;
    }

    textarea::placeholder {
      color: var(--color-base);
    }

    ::-webkit-scrollbar {
        width: 1rem;
      }
      
      ::-webkit-scrollbar-track {
        background: var(--color-base);
      }
      
      ::-webkit-scrollbar-thumb {
        background: var(--color-background-selected);
      }
      
      ::-webkit-scrollbar-thumb:hover {
        background: var(--color-background-hover);
      }
      
    // Select
    .custom-select__control {
        color: var(--color-base);
        background-color:var(--button-background);
        border: var(--border);
        border-radius: var(--border-radius);
        
        &:hover {
            background-color: var(--button-background-hover);
            border: var(--border-hover);
            cursor: pointer;
        }
      }
      
      .custom-select__menu {
        color: var(--color-base);
        background-color:var(--button-background);
        border: var(--border);
        border-radius: var(--border-radius);
      }
      
      .custom-select__option {
        transition: background-color 0.2s ease, color 0.2s ease;
        &:hover {
          background-color: var(--button-background-hover);
          color: var(--color-base);
          padding: var(--padding-base-x-4);
        }
        &--is-selected {
          background-color: var(--button-background-selected);
          color: var(--color-selected);
          cursor: pointer;
        }
      }
      
      .custom-select__single-value {
        color: var(--color-base);
        background-color: var(--button-background-selected);
        border: var(--border);
        border-radius: var(--border-radius);
        padding: var(--padding-base-x-2);
        cursor:pointer;
      }
      
    // Panels
    .panel {
        display: grid;
        color: var(--color-base);
        border: var(--border);
        border-radius: var(--border-radius);
        background-color: var(--color-background-transparent);
        width: 100%;
        height: calc(100% - 0px);
        overflow: hidden;
        padding: auto;
    }

    // Component Hover
    .component {
        border: var(--border);
        border-radius: var(--border-radius);
        background-color: var(--color-background-transparent);
        overflow: hidden;
      }
    .component:hover {
        background-color: var(--button-background-hover);
    }

    .component-inactive {
      border: var(--border);
      border-radius: var(--border-radius);
      background-color: var(--color-background-transparent);
    }
}
