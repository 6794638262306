@import 'variables';

.app {
    display: grid;
    grid-template-columns: 1fr; 
    grid-template-rows: auto 1fr auto; 
    height: calc(100% - 0px);
    width: 100%;
    overflow: hidden;
    z-index: 1;
}

.title {
    color: var(--color-selected);
    font-size: 2rem;
    margin: 0;
    padding: var(--padding-base);
    text-align: center;
}

.hero-background {
    background-image: url('./Assets/hero.webp');
    background-size: cover;
    background-position: center;
    height: 100%;
}

.page-container {
    height: calc(100% - 0px);
    width: 100%;
    overflow: hidden;
    z-index: 1;
}

// navbar.scss
.nav-container {
    width: 100%;
    background: var(--color-header-footer);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    padding: 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    z-index: 9999;
}

.nav-user-controls {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    height: 2rem;
    padding: 0 10px;
    cursor: pointer;
}

.nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 20px;
}

/* Styling for Navigation Links */
.nav a {
    text-decoration: none;
    color: var(--color-white);
    font-weight: bold;
    padding: 10px 15px;
    border-radius: 4px;
    transition: background-color 0.3s, color 0.3s;
}

.nav a:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

/* Styling for h1 */
.nav h1 {
    margin: 0;
    padding: 0;
    color: var(--color-white);
    font-size: 1.5em;
}

.user-controls {
    position: relative;
    margin-right: 2rem;
    z-index: 9999;
}
  
  .dropdown-menu {
    display: none; // Initially hidden
    position: absolute;
    right: 0;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 9999;

  
    button {
      color: black;
      padding: 12px 16px;
      text-decoration: none;
      display: block;
    }
  }
  
  // Style for when the dropdown is visible
  .dropdown-visible {
    display: flex;
    flex-direction: column;
    animation: slideIn 0.3s ease-out;
    z-index: 9999;
  }
  
  @keyframes slideIn {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
  
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  

/* Profile Thumbnail */
.profile-thumbnail {
    margin-right: 1rem;
    width: 30px;
    height: 30px;
    object-fit: cover;
    border: 2px solid var(--color-primary);
    border-radius: var(--border-radius-circle);
    box-shadow: var(--box-shadow);
}

.footer-container {
    height: 100%;
    width: 100%;
    overflow: hidden;
    background: var(--color-header-footer);

    .footer {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 100%;
        height: 100%;
        background-color: var(--color-modal-backdrop);
        color: var(--color-base);
        font-size: 0.75rem;
        padding: var(--padding-base-x-4);
    }
}
