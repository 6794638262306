@import '../variables';

.home-page {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10%;
    height: 100%;
    width: 100%;
    padding: 5% 10%;

    .box {
        position: relative;
        height: 100%;
        width: 100%;
        display: flex;
        font-size: xx-large;
        justify-content: center;
        align-items: center;
        text-align: center;
        background-color: var(--color-background-transparent);    
        color: #24EBF4;
        border-color: #24EBF4;
        border: #00e1ff 6px solid;
        border-bottom: none;
        border-radius: 1rem 1rem 0 0;
        z-index: 1;
        font-size: xx-large;
        font-style: bold;
    }
    
    .box-text {
        position: absolute;
        top: 10px;
    }

    .box-bkg {
        position: absolute;
        background: var(--color-header-footer);
        top: 0px;
        width: 100%;
        height: 15%;
    }

    .cta-button {
        width: 55%;
        height: 20%;
        position: absolute;
        bottom: 10%;
        left: 70%;
        transform: translateX(-55%);
        border-bottom: none;
        padding: var(--padding-base-x-4);
        z-index: 1;
        font-size: x-large;

    }

    .container {
        width: 100%;
        height: 100%;
        overflow: hidden;
        position: relative;
    }

    .container img {
        width: 250%;
        height: 125%;
        position: absolute;
        top: 0px;
        left: -150px;
    }
}
