@import '../variables';

// EventMap.scss
.event-activities-layout {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
    gap: var(--grid-gap-x-2);
    height: 100%;
    width: 100%;
    padding: var(--padding-base-x-4);
    overflow: hidden;

    .activities-list {
        align-items: center;
        display: grid;
        grid-template-columns: auto auto auto auto auto auto;
        gap: var(--grid-gap-x-4);
        height: 100%;
        width: 100%;
        padding: var(--padding-base-x-4);
    }

    .activity-editor {
        display: grid;
        grid-template-rows: auto 1fr;
        gap: var(--grid-gap-x-2);
        height: 100%;
        width: 100%;
        overflow: hidden;

        .activity-editor-header {
            display: grid;
            grid-template-columns: 4fr 1fr;
            gap: var(--grid-gap-x-4);
            height: 100%;
            width: 100%;
            padding: var(--padding-base-x-4);

            .activity-editor-header-left { 
                display: grid;
                grid-template-rows: auto 4fr;
                gap: var(--grid-gap);
                height: 100%;
                width: 100%;

                .activity-editor-header-active-name {
                    display: grid;
                    grid-template-columns: 4fr 1fr;
                    gap: var(--grid-gap-x-2);

                    .activity-editor-header-is-active {
                        display: grid;
                        grid-template-columns: 2rem auto;
                        gap: var(--grid-gap-x-2);
                        align-items: center;
                        height: 100%;
                        width: 100%;
                    }

                    .activity-editor-header-name {
                        display: grid;
                        grid-template-columns: auto 1fr;
                        gap: var(--grid-gap-x-2);
                        align-items: center;
                        height: 100%;
                        width: 100%;
                    }
                }
                .activity-editor-header-description-completed {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    gap: var(--grid-gap-x-2);
                    align-items: center;
                    height: 100%;
                    width: 100%;

                    .activity-editor-header-description {
                        display: grid;
                        grid-template-rows: auto 1fr;
                        gap: var(--grid-gap-x-2);
                        align-items: center;
                        height: 100%;
                        width: 100%;
                    }

                    .activity-editor-header-completed {
                        display: grid;
                        grid-template-rows: auto 1fr;
                        gap: var(--grid-gap-x-2);
                        align-items: center;
                        height: 100%;
                        width: 100%;
                    }
                }
            }

            .activity-editor-header-right {
                display: grid;
                grid-template-rows: 1fr 2fr 2fr;
                gap: var(--grid-gap);
                height: 100%;
                width: 100%;

                .activity-editor-header-is-timed {
                    display: grid;
                    height: 100%;
                    width: 100%;
                }
                .activity-editor-header-start-time {
                    display: grid;
                    height: 100%;
                    width: 100%;
                }
                .activity-editor-header-end-time {
                    display: grid;
                    height: 100%;
                    width: 100%;
                }
            }
        }

        .activity-editor-steps {
            display: grid;
            grid-template-columns: 2fr 8fr;
            gap: var(--grid-gap-x-2);
            height: 100%;
            width: 100%;
            overflow: hidden;

            .activity-editor-steps-container {
                display: grid;
                grid-template-rows: auto 1fr;
                height: 100%;
                width: 100%;
                padding: var(--padding-base-x-4);
                overflow: hidden;

                .activity-editor-steps-controls {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    gap: var(--grid-gap-x-2);
                    height: 100%;
                    width: 100%;
                    padding: var(--padding-base-x-4);
                }

                .activity-editor-steps-list {
                    display: flex;
                    flex-direction: column;
                    grid-template-rows: repeat(auto-fill, minmax(3rem, 3rem));
                    gap: var(--grid-gap-x-2);
                    height: calc(100% - 0px);
                    width: 100%;
                    padding: var(--padding-base-x-2);
                    overflow-x: hidden;
                    overflow-y: auto;

                    .activity-editor-step-selector {
                        display: grid;
                        grid-template-columns: 1fr 2rem;
                        align-items: center;
                        gap: var(--grid-gap-x-4);
                        height: 3rem;
                        width: 100%;
                        padding: var(--padding-base-x-2);
                        border: var(--border);
                        border-radius: var(--border-radius);
                        cursor: pointer;

                        .activity-editor-step-selector-name {
                            display: flex;
                            align-items: center;
                            height: 100%;
                            width: 100%;
                            min-width: 0;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                        }

                        .activity-editor-step-selector-delete-button {
                            display: block;
                            height: 100%;
                            width: 100%;

                            button {
                                height: 100%;
                                width: 100%;
                            }
                        }
                    }
                }
            }

            .activity-editor-steps-editor {
                display: grid;
                grid-template-columns: 1fr;
                height: 100%;
                width: 100%;
                padding: var(--padding-base-x-4);
            }
        }
    }

    .activity-step-editor {
        display: grid;
        grid-template-rows: auto auto auto 1fr;
        gap: var(--grid-gap-x-4);
        height: 100%;
        width: 100%;
        padding: var(--padding-base-x-4);
        overflow: hidden;

        .activity-step-editor-name {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: var(--grid-gap-x-4);
            height: max-content;
            width: 100%;
        }
       .activity-step-editor-description-complete {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: var(--grid-gap-x-4);
            height: 100%;
            width: 100%;

            .activity-step-editor-description-complete-item {
                display: grid;
                grid-template-rows: auto 1fr;
                gap: var(--grid-gap-x-2);
                height: 100%;
                width: 100%;
            }
        }

        .activity-step-editor-activity-type {
            display: grid;
            grid-template-columns: auto auto auto;
            gap: var(--grid-gap-x-4);
            height: 100%;
            width: 100%;
            
            .activity-step-editor-activity-type-item {
                display: grid;
                grid-template-rows: auto 1fr;
                gap: var(--grid-gap-x-2);
                height: 100%;
                width: 100%;
            }
        }
    }

    textarea {
        height: 100%;
        width: 100%;
    }
}
