@import '../variables';

.date-range {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: var(--grid-gap);
    align-items: center;
    width: 100%;
    height: 100%;
    padding: var(--padding-base-x-2);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    align-items: center;
    cursor: pointer;

    div {
        align-items: center;
        width: 100%;
        height: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}
