@import '../variables';

.signup-page {
  display: grid;
  grid-template-rows: auto auto;
  gap: var(--grid-gap-x-4);
  width: 100%;
  height: 50%;
  margin: auto;
  margin-top: 5%;
  text-align: center;

  .signup-page-header {
    display: grid;
    width: 100%;
    height: auto;
    overflow: hidden;
    padding: var(--padding-base-x-4);
    margin: auto;
  }

  .signup-page-social {
    display: grid;
    grid-template-rows: auto auto;
    width: 30%;
    height: auto;
    overflow: hidden;
    padding: var(--padding-base-x-4);
    margin: auto;

    .fb {
      background-color: #3B5998;
      color: white;
    }
    
    .twitter {
      background-color: #55ACEE;
      color: white;
    }
    
    .google {
      background-color: #dd4b39;
      color: white;
    }
  }

  .signup-page-email {
    display: grid;
    grid-template-rows: auto auto auto auto;
    width: 30%;
    height: auto;
    overflow: hidden;
    gap: var(--grid-gap-x-4);
    margin: auto;

    .signup-page-email-reset {
      display: grid;
      grid-template-rows: auto auto;
      width: 100%;
      height: auto;
      overflow: hidden;
      gap: var(--grid-gap-x-4);
      margin: auto;
    }
  }
}
