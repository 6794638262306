.message-container {
    position: fixed;
    top: 10%;
    left: 50%;
    transform: translateX(-50%);
    background-color: #f8f9fa;
    color: black;
    padding: 10px 20px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    z-index: 9999;
  
    &.fade-in {
      opacity: 1;
    }
  
    &.fade-out {
      opacity: 0;
    }
  }
  