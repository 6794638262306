@import '../variables';
.media-picker-backdrop{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    
    overflow: hidden;
    background-color: var(--color-bg-modal);
    z-index: 100;
}

.media-picker-content{
    width: 100%;
    height: 100%;
    
    overflow: hidden;
}
    .media-picker {
        position: absolute;
        top: 10%;
        left: 20%;
        padding: var(--padding-base-x-4);
        border: var(--border);
        width: 50%;
        height: 50%;
        
        overflow: hidden;

        .media-picker-grid {
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(6rem, 6rem));
            grid-template-rows: repeat(auto-fill, minmax(8rem, 8rem));
            gap: var(--grid-gap-x-4);
            width: 100%;
            height: 100%;
            overflow: auto; 

            .media-picker-thumbnail {
                width: 100%;
                height: 100%;
                
                overflow: hidden;
                cursor: pointer;
            }

            .image-item {
                cursor: pointer;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                border: var(--border);
                width: 100%;
                height: 100%;
                

                img {
                    width: 100%;
                    height: 100%;
                    
                    object-fit: contain;
                }

                p {
                    margin-top: 5px;
                    text-align: center;
                    font-size: 0.8em;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 100px;
                }
            }
        }
    }

    .media-thumbnail {
        display: grid;
        grid-template-rows: auto auto;
        width: 100%;
        height: 100%;
        
        overflow: hidden;
            
        .media-thumbnail-view{
            width: 100%;
            height: 100%;
            
            overflow: hidden;

            object-fit: cover;

            img, video, audio, canvas{
                width: 100%;
                height: 100%;
                
                object-fit: cover;
            }
        }
        
        .media-thumbnail-label {
            display: grid;
            text-align: center;
            height: 100%;
            width: 90%;
            
            padding: 0 var(--padding-base-x-4);
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
        
    .no-media-message {
        height: 100%;
        width: 100%;
        
    }
