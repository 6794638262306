@import '../variables';

.credit-card-signup {
    width: 100%;
    height: 100%;

    .credit-card-signup-form {
        display: grid;
        grid-template-rows: auto auto auto auto auto auto auto;
        gap: var(--grid-gap);
        align-items: center;
        width: 100%;
        height: 100%;
        
        padding: var(--padding-base-x-2);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        align-items: center;

        .credit-card-signup-form-element {
            display: grid;
            grid-template-columns: auto auto;
            gap: var(--grid-gap);
            align-items: center;
            width: 100%;
            height: 100%;
            
            padding: var(--padding-base-x-2);
        }

        .credit-card-signup-form-phone-email {
            display: grid;
            grid-template-columns: auto auto;
            gap: var(--grid-gap);
            align-items: center;
            width: 100%;
            height: 100%;
            
            padding: var(--padding-base-x-2);
        }

        .credit-card-signup-form-address {
            display: grid;
            grid-template-rows: auto auto;
            gap: var(--grid-gap);
            align-items: center;
            width: 100%;
            height: 100%;
            
            padding: var(--padding-base-x-2);

            .credit-card-signUp-form-address-street {
                display: grid;
                gap: var(--grid-gap);
                align-items: center;
                width: 100%;
                height: 100%;
                
                padding: var(--padding-base-x-2);
            }
            .credit-card-signup-form-address-postal{
                display: grid;
                grid-template-columns: auto auto auto auto;
                gap: var(--grid-gap);
                align-items: center;
                width: 100%;
                height: 100%;
                
                padding: var(--padding-base-x-2);
            }
        }

        .credit-card-signup-form-credit-card {
            display: grid;
            grid-template-rows: auto auto;
            gap: var(--grid-gap);
            align-items: center;
            width: 100%;
            height: 100%;
            
            padding: var(--padding-base-x-2);

            .credit-card-signup-form-credit-card-exp-cvv {
                display: grid;
                grid-template-columns: auto auto;
                gap: var(--grid-gap);
                align-items: center;
                width: 100%;
                height: 100%;
                
                padding: var(--padding-base-x-2);
            }
        }
    }
}