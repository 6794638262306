@import '../variables';

.threeDMap {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 3rem;
    gap: var(--grid-gap);
    width: 100%;
    height: 100%;
    overflow: hidden;

    .threeDMap-viewport {

    }

    .threeDMap-viewport-ui {
        position: absolute;
        top: .5rem;
        left: .5rem;
        // width: 100%;
        // height: 100%;
        
        .toggle-view-button {
            pointer-events: auto;
        }
    }

}

.threeDMap-values-editor{
    display: grid;
    grid-template-columns: auto auto auto 1fr;
    gap: var(--grid-gap-x-4);
    height: 100%;
    width: 100%;
    
    padding: var(--padding-base-x-4);

    .threeDMap-values-editor-inputs {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: var(--grid-gap-x-4);
        height: 100%;
        width: 100%;
        
        padding: var(--padding-base);

        input {
            cursor: pointer;
            height: 1.5rem;
            max-width: 4.5rem;
        }
    }
}