@import '../variables';

.beta-access {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    z-index: 9999;

    .beta-access-content {
        display: flex;
        flex-direction: column;
        gap: .5rem;
        align-items: center;
        justify-content: center;
        padding: var(--padding-base-x-8);
    }
}