@import '../variables';

.event-list {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto 1fr;
  gap: var(--grid-gap-x-4);
  width: 100%;
  height: 100%;
  
  overflow: hidden;
}

.event-list-header {
  align-items: center;
  text-align: center;
  background-color: var(--color-background);
  color: var(--color-base);
  border: var(--border);
  border-radius: var(--border-radius);
  padding: var(--padding-base-x-4);
  width: 100%;
  height: auto;
  
}

.event-list-item-container {
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(auto-fill, minmax(18%, 18%));
  gap: var(--grid-gap-x-4);
  height: calc(100% - 0px);
  width: 100%;
  height: 100%;
  
  overflow-y: auto;
  padding: var(--padding-base-x-4);
}

.event-list-item-new {
  display: grid;
  grid-template-rows: 1fr;
  gap: var(--grid-gap-x-4);
  height: fit-content;
  height: -moz-fit-content;
  width: 100%;
  
  background-color: var(--color-background);
  color: var(--color-base);
  border: var(--border);
  border-radius: var(--border-radius);
  padding: var(--padding-base-x-4);

  button {
    width: 100%;
    height: 100%;
    
  }
}

.event-list-item {
  display: grid;
  grid-template-rows: auto auto auto auto;
  gap: var(--grid-gap-x-4);
  justify-content: center;

  background-color: var(--color-background);
  color: var(--color-base);
  border: var(--border);
  border-radius: var(--border-radius);
  padding: var(--padding-base-x-4);
  height: fit-content;
  height: -moz-fit-content;
  width: 100%;
  

  .event-list-item-details-container {
    display: grid;
    justify-content: center;
    grid-template-rows: auto 6rem;
    gap: var(--grid-gap-x-2);
    width: 100%;
    height: 100%;
    

    .event-list-item-event-name {
      display: flex;
      width: 100%;
      height: 100%;
      
      text-align: center;
    }

    .event-list-item-event-icon {
      width: 6rem;
      height: 100%;
      

      svg, img {
        width: calc(100% - 0px);
        height: calc(100% - 0px);
        object-fit: contain;
    }
    }
  }

  .event-list-item-event-description {
    width: 100%; 
    height: 100%;
    
    overflow: hidden; 
    white-space: nowrap; 
    text-overflow: ellipsis;
  }

  .event-list-item-event-stats {
    display: grid;
    grid-template-rows: auto auto auto auto auto;
    width: 100%;
    height: 100%;
    
  }

  .event-list-item-event-edit {
    width: 100%;
    height: 100%;
    

    button {
      width: 100%;
      height: 100%;
      
    }
  }
}