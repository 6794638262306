@import '../../variables';


#root .date-range-editor {
    display: grid ;
    grid-template-columns: auto;
    grid-template-rows: auto auto auto auto auto;
    height: 100%;
    width: 100%;
    
}

#root .date-range-editor-title {
    height: 100%;
    width: 100%;
    
}

#root .date-range-editor-instructions {
    height: 100%;
    width: 100%;
    
}

#root .date-range-editor-text {
    height: 100%;
    width: 100%;
    
}

#root .date-range-editor-calendar-date-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: 100%;
    width: 100%;
    

    #root .date-range-editor-calendar-container {
        display: grid;
        grid-template-rows: auto auto auto;
        height: 100%;
        width: 100%;
        
    
        #root .date-range-editor-label {
            height: 100%;
            width: 100%;
            
        }

        #root .date-range-editor-calendar {
            height: 100%;
            width: 100%;
            
        }
        #root .date-range-editor-time {
            height: 100%;
            width: 100%;
            
        }
    }
}

#root .date-range-editor-save-button-container {
    height: 100%;
    width: 100%;
    
}

// Calendar overrides
#root .react-calendar__month-view__days__day {
    color: var(--color-base);
    background-color: var(--modal-background);
    border: var(--border-calendar);
    border-radius: var(--border-radius);
}

#root .react-calendar {
    color: var(--color-base);
    background-color: var(--modal-background);
}

#root .react-calendar__month-view__days__day:hover {
    color: var(--color-base);
    background-color: var(--modal-background);
}
  
#root .react-calendar__tile--active {
    color: var(--color-base);
    background-color: var(--modal-background);
}

.react-calendar__month-view__days__day.react-calendar__tile--weekend {
    color: var(--color-selected);
    background-color: var(--modal-background);
}

  
.react-calendar__month-view__days__day.react-calendar__tile--now {
    border: 2px solid #066df3 !important;
    color: #066df3 !important;
}

/* Style for the month label */
.react-calendar__navigation__label {
    font-size: 20px;
    color: var(--color-base);
    background-color: var(--modal-background);
  }
  
  /* Style for the back arrow */
  .react-calendar__navigation__prev-button {
    cursor: pointer;
    color: var(--color-base);
    background-color: var(--modal-background);
  }
  
  /* Style for the forward arrow */
  .react-calendar__navigation__next-button {
    cursor: pointer;
    color: var(--color-base);
    background-color: var(--modal-background);
}

/* Style for the previous range (year) button with two arrows */
.react-calendar__navigation__prev2-button {
    color: var(--color-base);
    background-color: var(--modal-background);
    border: none;
    cursor: pointer;
  }
  
  /* Style for the next range (year) button with two arrows */
  .react-calendar__navigation__next2-button {
    color: var(--color-base);
    background-color: var(--modal-background);
    cursor: pointer;
  }
  
  /* Hover effect for both buttons */
  .react-calendar__navigation__prev2-button:hover,
  .react-calendar__navigation__next2-button:hover {
    color: var(--color-base);
    background-color: var(--modal-background);
  }

  .react-calendar__month-view__weekdays__weekday {
    color: var(--color-base);
    background-color: var(--modal-background);
  }

  .react-calendar__month-view {
    color: var(--color-base);
    background-color: var(--modal-background);
}

/* Highlighting for start date */
.calendar-highlight-start {
    color: var(--color-selected);
    background-color: var(--button-background) !important;
    border: var(--border) !important;
}

#root .calendar-weekend-style {
    color: var(--color-selected) !important;
    background-color: var(--modal-background) !important;
}

// custom calendar styles
#root .date-range-selected {
    color: var(--color-selected);
    background-color: var(--button-background);
    border: var(--border-calendar);
}


// Timepicker
// Timepicker overrides
.react-time-picker__clock-button,
.react-time-picker__clear-button {
    display: none !important;
}

.react-time-picker__inputGroup input {
    min-width: 4rem;
    max-height: 1.5rem;
}

.react-time-picker__inputGroup__spinner {
    opacity: 1 !important;
    visibility: visible !important;
}

.react-time-picker__inputGroup__input {
    height: 1.5rem;
    color: var(--color-base);
    background-color: var(--button-background);
    border: var(--border) !important;
}

// Location editor
.location-editor-ui {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    height: 75vh;
    width: 75vw;
    
}