@import '../variables';

.site-details {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
    gap: var(--grid-gap);
    height: 100%;
    width: 100%;
    
    padding: var(--padding-base);
    overflow: hidden;

    .site-details-sites-list {
        display: grid;
        grid-template-columns: auto 1fr auto auto;
        gap: var(--grid-gap);
        height: 100%;
        width: 100%;
        
        overflow: hidden;
        padding: var(--padding-base);
    }

    .site-details-menu {
        display: grid;
        grid-template-columns: auto 1fr;
        gap: var(--grid-gap);
        height: 100%;
        width: 100%;
        
        overflow: hidden;
        padding: var(--padding-base);

        .site-details-left {
            display: grid;
            grid-template-rows: 1fr 1fr 1fr;
            gap: var(--grid-gap-x-2);
            height: 100%;
            width: 100%;
            
            overflow: hidden;

            .site-details-site-icon-container {
                display: grid;
                grid-row: 1 / 3;
                width: 4rem;
                height: auto;
                
            }

            .site-details-site-deactivate-container {
                display: grid;
                grid-template-columns: auto;
                grid-template-rows: auto;
                width: 100%;
                height: 100%;
                
                padding: 0;

                button {
                    font-size: 8pt;
                    
                }
            }
        }

        .selected-site-inputs {
            display: grid;
            grid-template-rows: 2rem 2rem 2rem;
            gap: var(--grid-gap);
            height: 100%;
            width: 100%;
            
            overflow: hidden;
            padding: var(--padding-base);
        }
    }
}