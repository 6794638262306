@import '../variables';

.join-page {
  display: grid;
  grid-template-rows: 2rem 1fr 2rem;
  width: 100%;
  height: calc(100% - 0px);
  padding: var(--padding-base-x-8);
  overflow: hidden;

  .join-page-title {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: xx-large;
    font-weight: bold;
  }

  .join-page-body {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: var(--gap-base-x-4);
    width: 100%;
    height: calc(100% - 0px);
    overflow: hidden;

    .join-page-body-text {
      display: grid;
      grid-template-rows: auto 1fr;
      gap: var(--gap-base-x-4);
      width: 100%;
      height: 100%;
      overflow: hidden;
      padding: var(--padding-base-x-8);
      font-size: x-large;
      line-height: 1.5;
    }
  
    .join-page-body-image {
      display: grid;
      width: 100%;
      height: calc(100% - 0px);
      overflow: hidden;
      padding: var(--padding-base-x-8);
    }

    .join-page-body-image img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border: var(--border);
      border-radius: 2rem;
    }
  }

  .join-page-footer {
    display: flex;
    align-items: center;
  }
}
