@import '../variables';

.icon-container {
    border: var(--border);
    border-radius: var(--border-radius);
    display: grid;
    width: calc(100% - 0px);
    height: calc(100% - 0px);
    
    svg, img {
        width: calc(100% - 0px);
        height: calc(100% - 0px);
        object-fit: contain;
    }
}
