@import '../variables';

.tutorial-page {
    display: grid;
    grid-template-rows: auto 1fr auto;
    width: 100%;
    height: 100%;
    padding: var(--padding-base-x-8);
    overflow: hidden;

    .tutorial-page-step-container {
        width: 100%;
        height: 100%;
        overflow: hidden;

        .tutorial-page-step {
            display: grid;
            grid-template-columns: 1fr 1fr;
            width: 100%;
            height: 100%;
            padding: var(--padding-base-x-8);

            .content {
                flex: 1;
                margin-right: 20px;
            }

            .image {
                flex-basis: 100%;
                max-width: 100%;
                height: auto;

                img {
                    width: 100%;
                    height: auto;
                    border-radius: 8px;
                    border: var(--border);
                    border-radius: 2rem;
                }
            }
        }
    }
    .navigation-button{

    }
}