@import '../variables';

.subscriptions {
    display: grid;
    grid-template-rows: auto max-content auto 1fr;
    width: 100%;
    height: 100%;
    padding: var(--padding-base-x-2);
    z-index: 100;
    overflow: hidden;
    overflow-y: scroll;

    .subscriptions-header {
        display: flex;
        flex-direction: row;
        text-align: center;
        align-items: center;
        align-content: center;
        font-size: 24px;
        padding: var(--padding-base-x-4);

    }

    .subscriptions-list {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        gap: var(--grid-gap-x-2);
        width: 100%;
        height: 100%;
        
        padding: var(--padding-base-x-2);
        z-index: 100;
        overflow: hidden;
    }

    .subscriptions-list-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: var(--grid-gap-x-4);
        width: 100%;
        max-width: 20rem;
        padding: var(--padding-base-x-8);
        border: var(--border);
        overflow: hidden;


        .subscriptions-list-item-name {
            font-size: 24px;
            font-weight: bold;
        }
        .subscriptions-list-item-description { }
    }

    .subscriptions-list-item.selected {
        border: var(--border-selected);
        color: var(--color-selected);
        background-color: var(--color-background-selected);
    }
}
