@import '../variables';

.media {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto 1fr;
  gap: var(--grid-gap);
  height: 100%;
  width: 100%;
  
  overflow: hidden;
  padding: var(--padding-base);
  color: var(--color-base);

  .media-details-menu{
    display: grid;
    grid-template-columns: auto 1fr;
    gap: var(--grid-gap);
    height: 100%;
    width: 100%;
    
    overflow: hidden;
    padding: var(--padding-base);

    .media-details-left {
      display: grid;
      grid-template-rows: 1fr 1fr 1fr;
      gap: var(--grid-gap-x-2);
      height: 100%;
      width: 100%;
      overflow: hidden;

      .selected-media-thumbanil-container {
        display: grid;
        grid-row: 1 / 3;
        width: 4rem;
        height: 4.25rem;
        overflow: hidden;
        cursor: pointer;

        img {
          width: 4rem;
          height: 100%;
          object-fit: cover;
        }
      }
  
      .selected-media-deactivate-container {
        display: grid;
        grid-template-columns: auto;
        grid-template-rows: auto;
        width: 100%;
        height: 100%;
        
        padding: 0;

        button {
            font-size: 8pt;
            
        }
      }
    }

    .selected-media-inputs { 
      display: grid;
      grid-template-rows: 2rem 2rem 2rem;
      gap: var(--grid-gap);
      height: 100%;
      width: 100%;
      overflow: hidden;
      padding: var(--padding-base);
    }
  }

  .media-add-new-container {
    height: 100%;
    width: 100%;
    
    padding: var(--padding-base-x-2);
    button {
      width: 100%;
    }
  }

  .media-list-container {
    height: 100%;
    width: 100%;
    overflow: hidden;
  }

  .media-list {
    display: flex;
    flex-direction: column;
    gap: var(--grid-gap);
    height: calc(100% - 10px);
    width: 100%;
    overflow-y: auto;
    padding: var(--padding-base-x-2);

    .media-item, .media-item-selected {
      align-items: center;
      display: grid;
      gap: var(--grid-gap-x-2);
      grid-template-columns: 32px 1fr auto;
      color: var(--color-base);
      background-color:var(--button-background);
      border: var(--border);
      border-radius: var(--border-radius);
      padding: var(--padding-base-x-2);;
      width: 100%; 
      cursor: pointer;

      .selected-media-viewer {
        width: 2rem;
        height: 2rem;
        margin-right: 1rem;
      }

      svg, img {
        flex-shrink: 0;
        width: 2rem;
        height: 2rem;
        margin-right: 1rem;
        fill: #fff !important;
      }
      
      .media-text {
        overflow: hidden;
        white-space: nowrap;   
        text-overflow: ellipsis;
        max-width: calc(100% - 40px);
      }
    }

    .media-item:hover {
      background-color: var(--button-background-hover);
      border: var(--border-hover);
      cursor:pointer;
    }

    .media-item-selected {
      color: var(--color-selected);
      border: var(--border-selected);
      background-color: var(--button-background-selected);
      width: 100%; 
      
      cursor:default;

      svg{
        fill: var(--color-selected);
      }
    }
  }
}

