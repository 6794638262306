@import '../variables';

.event-details {
    display: grid;
    grid-template-columns: 2fr 5fr;
    width: 100%;
    height: 100%;
    padding: var(--padding-base-x-4);

    .event-details-left {
        display: grid;
        grid-template-rows: auto auto auto auto auto 1fr;
        gap: var(--grid-gap-x-2);
        width: 100%;
        height: 100%;
        padding: var(--padding-base-x-4);
        border:var(--border);
        border-radius: var(--border-radius);

        .event-details-icon-details { 
            display: grid;
            grid-template-columns: auto 1fr;
            gap: var(--grid-gap-x-2);
            width: 100%;
            height: 100%;

            .event-details-icon {
                display: grid;
                width: 5rem;
                height: 5rem;
                cursor: pointer;
            }

            .event-details-menu {
                display: grid;
                grid-template-rows: auto auto;
                gap:var(--grid-gap-x-2);
                height: 100%;
                width: 100%;
            
                .event-details-buttons {
                    display: grid;
                    grid-template-columns: auto auto;
                    gap:var(--grid-gap-x-2);
                    height: 100%;
                    width: 100%;
                }

                .event-details-name-description {
                    display: grid;
                    height: 100%;
                    width: 100%;
                }
            }
        }

        .event-details-time-details {
            display: grid;
            grid-template-columns: auto auto;
            gap: var(--grid-gap-x-2);
            width: calc(100% - 0px);
            height: calc(100% - 0px);
            
            padding: var(--padding-base-x-4);
            border:var(--border);
            border-radius: var(--border-radius);
        }

        .event-details-tags {
            .event-details-tags-assigned {
                .event-details-tags-assigned-list{
                    display: flex;
                    flex-wrap: wrap;
                    flex-direction: row;
                    gap: var(--grid-gap-x-2);
                    width: calc(100% - 0px);
                    height: calc(100% - 0px);
                    padding: var(--padding-base-x-4);
                    border:var(--border);
                    border-radius: var(--border-radius);
                }
            }
        }
        .event-details-sites-list {
            display: grid;
            grid-template-rows: auto auto 1fr;
            gap: var(--grid-gap-x-2);
            width: calc(100% - 0px);
            height: calc(100% - 0px);

            .event-details-sites-list-item {
                display: flex;
                align-items: center;
                width: calc(100% - 0px);
                height: 2rem;
                
                padding: var(--padding-base-x-4);
                border: var(--border);
                border-radius: var(--border-radius);
            }
        }
    }

    .event-details-right {

    }

    .event-details-properties {
        display: grid;
        grid-template-columns: auto 1fr ;
        height: 100%;
        width: 100%;

        .event-details-event-icon {
            height: 100%;
            width: 100%;
        }
    }

    .event-details-stats {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto 1fr;
        height: 100%;
        width: 100%;

        .event-details-stats-menu{
            height: 100%;
            width: 100%;
        }

        .event-details-stats-container{
            height: 100%;
            width: 100%;

            .event-details-stats-sites{
                height: 100%;
                width: 100%;
                
            }
        }
    }
}