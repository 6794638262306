@import '../variables';

.event-editor {
  display: grid;
  grid-template-columns: 2fr 6fr;
  grid-template-rows: 1fr;
  gap: var(--grid-gap);
  width: 100%;
  height: 100%;
  
  overflow: hidden;
  z-index: 9999;

  .event-editor-left-panel {
    display: grid;
    grid-template-rows: auto auto 1fr;
    gap: var(--grid-gap);
    width: 100%;
    height: 100%;
    
    overflow: hidden;

    .event-menu {
      width: 100%;
      height: 100%;
      
    }

    .site-details-container {
      width: 100%;
      height: 100%;
      
      overflow: hidden;
    }

    .media-details-container {
      width: 100%;
      height: 100%;
      
      overflow: hidden;
    }
  }
  
  .viewport-container {
    width: 100%;
    height: 100%;
    
    overflow: hidden;
  }
}