@import '../variables';

.site-map-label {
    padding: 2px 5px;
    font-size: 12px;
    border: 1px solid black;
    border-radius: 3px;
    width: max-content;
}

.site-map {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto 1fr;
    width: 100%;
    height: 100%;
    background-color: #fff;
  }